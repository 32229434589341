import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'

const initialState = {
  template_id: null,
  templates: [],
  items: [],
  simple_headline: true,
  campaign_name: 'New Campaign',
  ab_campaign_messages: [],
  html_snippets: [],
  utm_campaign: '',
  utm_medium: '',
  utm_source: '',
  utm_term: '',
  sender_name: '',
  subject: '',
  initial_message_name: '',
  message_name: '',
  headline: '',
  preheader: '',
  follow_up_text: '',
  is_mobile: false,
  esp_data: {
    lists: [],
    segments: [],
    campaigns: [],
    admin: false
  },
  campaign: '',
  segment: '',
  scheduled_time: '',
  selected_lists: [],
  error: ''
}

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TEMPLATES':
      return {
        ...state,
        templates: action.payload
      }
    case 'ADD_LINK':
      return {
        ...state,
        links: [...state.links, {type: 'link', value: ''}]
      }
    case 'SET_ITEMS':
      return {
        ...state,
        items: action.payload
      }
    case 'SET_SIMPLE_HEADLINE':
      return {
        ...state,
        simple_headline: action.payload
      }
    case 'SET_CAMPAIGN_NAME':
      return {
        ...state,
        campaign_name: action.payload
      }
    case 'SET_AB_CAMPAIGN_MESSAGES':
      return {
        ...state,
        ab_campaign_messages: action.payload
      }
    case 'SET_HTML_SNIPPETS':
      return {
        ...state,
        html_snippets: action.payload
      }
    case 'SET_GOOGLE_SHEET_OUTPUTS':
      return {
        ...state,
        google_sheet_outputs: action.payload
      }
    case 'SET_UTM_CAMPAIGN':
      return {
        ...state,
        utm_campaign: action.payload
      }
    case 'SET_UTM_MEDIUM':
      return {
        ...state,
        utm_medium: action.payload
      }
    case 'SET_UTM_SOURCE':
      return {
        ...state,
        utm_source: action.payload
      }
    case 'SET_UTM_TERM':
      return {
        ...state,
        utm_term: action.payload
      }
    case 'SET_SENDER_NAME':
      return {
        ...state,
        sender_name: action.payload
      }
    case 'SET_SUBJECT':
      return {
        ...state,
        subject: action.payload
      }
    case 'SET_INITIAL_MESSAGE_NAME':
      return {
        ...state,
        initial_message_name: action.payload
      }
    case 'SET_MESSAGE_NAME':
      return {
        ...state,
        message_name: action.payload
      }
    case 'SET_HEADLINE':
      return {
        ...state,
        headline: action.payload
      }
    case 'SET_PREHEADER':
      return {
        ...state,
        preheader: action.payload
      }
    case 'SET_FOLLOW_UP_TEXT':
      return {
        ...state,
        follow_up_text: action.payload
      }
    case 'SET_TEMPLATE':
      return {
        ...state,
        ...action.payload
      }
    case 'SET_DEFAULT_FIELDS':
      return {
        ...state,
        template_id: action.payload[0].id,
        templates: action.payload,
        utm_source: action.payload[0].utm_source,
        utm_term: action.payload[0].utm_term,
        utm_medium: action.payload[0].utm_medium,
        utm_campaign: action.payload[0].utm_campaign,
        sender_name: action.payload[0].sender.name
      }
    case 'SET_MOBILE':
      return {
        ...state,
        is_mobile: !state.is_mobile
      }
    case 'SET_ESP_DATA':
      return {
        ...state,
        esp_data: {
          ...action.payload
        }
      }
    case 'SET_CAMPAIGN':
      return {
        ...state,
        campaign: action.payload
      }
    case 'SET_SEGMENT':
      return {
        ...state,
        segment: action.payload
      }
    case 'SET_SEND_DATA':
      return {
        ...state,
        ...action.payload
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      }
    case 'SET_UTMS':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default function configureStore() {
  return createStore(rootReducer, initialState, applyMiddleware(thunk))
}
