import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {parseLiquidSource} from "../../utils/liquidParser"

const Buttons = () => {
  let {items, html_snippets, google_sheet_outputs} = useSelector(state => state)
  const dispatch = useDispatch()

  const addLink = () => {
    const newItem = {
      type: 'Link',
      value: '',
      followNumber: items.length
    }
    dispatch({type: 'SET_ITEMS', payload: [...items, newItem]})
  }

  const addSnippet = () => {
    const newItem = {
      type: 'html_snippet',
      value: html_snippets[0].id,
      content: parseLiquidSource(html_snippets[0].liquid_source, google_sheet_outputs),
      followNumber: items.length,
      short_name: html_snippets[0].short_name
    }
    dispatch({type: 'SET_ITEMS', payload: [...items, newItem]})
  }

  return(
    <>
      <button className="btn btn-success btn-add-link-html" type="button" onClick={addLink}>Add Link</button>
      <button className="btn btn-success btn-add-html-snippet" type="button" onClick={addSnippet}>Add Snippet</button>
    </>
  )
}

export default Buttons
