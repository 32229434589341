import React, {useEffect} from 'react'
import PanelHeading from './PanelHeading'
import Buttons from './Buttons'
import Table from './table'
import {useDispatch, useSelector} from 'react-redux'

const PostsForm = ({facebook_posts}) => {
  const items = useSelector(state => state.items)
  const dispatch = useDispatch()

  useEffect(() => {
    facebook_posts = facebook_posts.map(post => {
      return {...post, type: 'facebook_post', image_url: post.image.thumb.url, title: post.name, url: post.link}
    })

    dispatch({type: 'SET_ITEMS', payload: [...items, ...facebook_posts]})

    // fetch only unarchived html snippets by passing a search query parameter with _eq ending specified by ransack
    fetch('html_snippets.json?q[archived_eq]=false').then(res => res.json()).then(data => {
      data = data.map((data, idx) => {
        return {
          ...data,
          followNumber: idx
        }
      })
      dispatch({type: 'SET_HTML_SNIPPETS', payload: data})
    })

    fetch('todays_google_sheet_data.json').then(res => res.json()).then(data => {
      dispatch({type: 'SET_GOOGLE_SHEET_OUTPUTS', payload: data})
    })
  }, [])

  return (
    <div className="panel panel-default">
      <PanelHeading collapseId={'posts_for_html'}>
        Posts
      </PanelHeading>
      <div className="panel-collapse collapse in" id="posts_for_html">
        <div className="panel-body">
          {/*Use sortable react instead of table*/}
          <Table/>
          <Buttons/>
        </div>
      </div>
    </div>
  );
}

export default PostsForm
