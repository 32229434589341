import consumer from '../consumer'

document.addEventListener('DOMContentLoaded', () => {
  if ($('#google-sheet-syncs-edit').length > 0) {
    const updateSyncStatusTable = (data) => {
      const lastSyncAt = convertDateString(data["last_sync_at"])
      const errorMessage = data["error_message"] || 'No errors'

      $('#last-sync-at').text(lastSyncAt)
      $('#error-message').text(errorMessage)
    }

    const convertDateString = (dateString) => {
      // Parse the input date string into a JavaScript Date object
      const date = new Date(dateString);

      // Create a formatter for Central Time (US & Canada)
      const options = {
        timeZone: 'America/Chicago', // Central Time (CT)
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true // 12-hour format with AM/PM
      };

      // Format the date according to Central Time
      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(date);

      // Rebuild the formatted date string with appropriate spaces and punctuation
      let formattedDate = '';
      for (const part of parts) {
        if (part.type === 'literal' && part.value === ' at ') {
          formattedDate += ' '
          continue;
        }
        switch (part.type) {
          case 'month':
          case 'day':
          case 'year':
          case 'hour':
          case 'minute':
          case 'second':
          case 'dayPeriod':
            formattedDate += part.value; // Add the actual date/time parts
            break;
          case 'literal':
            formattedDate += part.value; // Add spaces, commas, etc.
            break;
        }
      }

      return formattedDate;
    }

    consumer.subscriptions.create('GoogleSheetSyncChannel',
      {
        received(data) {
          updateSyncStatusTable(data)
        }
      }
    )
  }
})
