import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {SortableElement} from "react-sortable-hoc";
import {parseLiquidSource} from "../../../utils/liquidParser"

const HtmlSnippet = SortableElement(({followNumber, removeItem}) => {
  const {html_snippets, items, google_sheet_outputs} = useSelector(state => state)
  const snippet = items[followNumber]
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const index = html_snippets.findIndex(snippet => snippet.id === parseInt(e.target.value))
    const item = {
      ...snippet,
      value: e.target.value,
      content: parseLiquidSource(html_snippets[index].liquid_source, google_sheet_outputs),
      short_name: html_snippets[index].short_name
    }

    items[followNumber] = item
    dispatch({type: 'SET_ITEMS', payload: [...items]})
  }

  return (
    <>
      <tr>
        <td>
          <i className='fa fa-arrows'/>
        </td>
        <td>
          <label className="col-sm-1 p-l-0 p-t-0 control-label string required"
                 htmlFor="published_email_html_snippets_attributes_html_snippet_id">
            HTML Snippet
          </label>
        </td>
        <td>
          <select className="form-control string html_snippet_id" type="text" defaultValue={snippet.value}
                  name="published_email_html_snippets_attributes[html_snippet_id][]" onChange={handleChange}>
            {html_snippets.map(({id, name}) => <option key={`${id}-${followNumber}`} value={id}>{name}</option>)}
          </select>
        </td>
        <td className="col-md-1 p-t-10 p-r-15">
          <i className="fa fa-close text-danger pull-right remove-snippet-button" onClick={removeItem}/>
        </td>
      </tr>
    </>
  )
})

export default HtmlSnippet
