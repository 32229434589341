import { Liquid } from 'liquidjs';

// Create a Liquid engine instance
const engine = new Liquid();

// Register a custom filter to handle default values
engine.registerFilter('preserve', (value, key) => {
  return value !== undefined ? value : `{{ ${key} }}`;
});

// Function to preprocess the template
const preprocessTemplate = (template) => {
  return template.replace(/{{\s*([\w]+)\s*}}/g, '{{ $1 | preserve: "$1" }}');
};

export const parseLiquidSource = async (source, outputs) => {
  try {
    // Preprocess the template to wrap variables in the preserve filter
    const processedSource = preprocessTemplate(source);

    // Render the processed template
    return await engine.parseAndRender(processedSource, outputs);
  } catch (error) {
    console.error('Error parsing liquid source:', error);
  }
};
